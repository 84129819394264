// Data
import { useFirebolt } from "@iq-firebolt/client"
import dataHashsMobi from "@mkt/ui/data/mobi-hashs.json"

// Utils
import formatMobiUrl, { shouldAvoidSkipper } from "@mkt/utils/formatMobiUrl"
import formatUrlBasedOnUtm from "@mkt/utils/formatUrlBasedOnUtm"
import getRandomHashMobi from "@mkt/utils/getRandomHashMobi"
import formatAutofillUrl from "@mkt/utils/formatAutofillUrl"
import { formatUrlBasedAnonymousID } from "@mkt/utils/formatUrlBasedAnonymousID"
import getSortingHatUrlParams from "@mkt/utils/getSortingHatUrlParams"
import replaceAppsflyerUrlParams from "@mkt/utils/replaceAppsflyerUrlParams"
import replaceItauPfUrlParams from "@mkt/utils/replaceItauPfUrlParams"

import { useTrack } from "@mkt/analytics/redline/track"

import useStoryblokIntegrations from "./useStoryblokIntegrations"
import useAnalytics from "./useAnalytics"
import { getSiteIdParam } from "@mkt/utils/getSiteIdParam"
import { getCookie } from "@mkt/utils/cookie"
import useStoryblokDimensions from "@mkt/utils/storyblok/useStoryblokDimensions"
import { getRDG } from "@mkt/utils/getRDG"
import { getDeliveryIdParam } from "@mkt/utils/getDeliveryIdParam"

const useRedirects = ({ content }) => {
  const { track } = useTrack()
  const { formEndPayload, capturedData } = useFirebolt()
  const { dispatchGlobalError, anonymousId, fireboltId } = useAnalytics()
  const { getIntegrationProps } = useStoryblokIntegrations({ content })
  const dimensions = useStoryblokDimensions("offer-url-parameters")
  const redirectListFirebolt =
    formEndPayload?.webhookResult?.processedData?.storyblok_redirect_urls
  const redirectList =
    (redirectListFirebolt?.length ?? 0) > 0
      ? redirectListFirebolt
      : content?.redirect_list

  function getUrlWithAnonymousId({
    choosenCard,
    refusedSHSuggestion,
    propsMobiCard,
    id,
    externalId,
    token,
  }) {
    const { redirect_anonymous_id: redirectAnonymousID } = content
    const isMobiCard = propsMobiCard?.card_id && externalId

    const randomHash =
      refusedSHSuggestion === "click-platinum"
        ? "MhXBMIZMAg"
        : getRandomHashMobi(dataHashsMobi)

    const urlMobi = formatMobiUrl({
      anonymousId,
      cardId: propsMobiCard?.card_id,
      cardSlug: choosenCard.choosen_card,
      cardType: propsMobiCard?.card_type,
      externalId,
      token,
      randomHash,
      fireboltId,
    })

    const urlWithRedirect = formatUrlBasedOnUtm({
      redirectList: redirectList,
      cardSlug: choosenCard.choosen_card,
      userProductId: id,
      anonymousId,
    })

    const defaultUrl = replaceAppsflyerUrlParams({
      url: content?.redirect?.url,
      cardSlug: choosenCard.choosen_card,
      userProductId: id,
      anonymousId,
    })

    const switchUrl = isMobiCard
      ? urlMobi
      : urlWithRedirect
      ? urlWithRedirect
      : defaultUrl

    const isItauPF =
      !!choosenCard?.choosen_card && choosenCard?.choosen_card === "itau-pf"
    if (isItauPF) {
      return content?.redirect?.url
    }

    const urlFormatted = formatUrlBasedAnonymousID({
      switchUrl,
      redirectAnonymousID,
      anonymousId,
    })
    const completedUrl = fillParams(urlFormatted)

    return completedUrl
  }

  async function makeHandleRedirect({
    choosenCard,
    content,
    mobiPayload = {},
    refusedSHSuggestion = false,
    id,
    cameFromSortingHat = false,
    trackFormErrored,
  }) {
    const propsMobiCard = getIntegrationProps("component_mobi_card")
    let { externalId, token } = mobiPayload

    if (shouldAvoidSkipper(propsMobiCard?.card_id)) {
      externalId = "no-externa-id"
      token = "no-token"
    } else {
      if (propsMobiCard?.card_id && !externalId && !token) {
        dispatchGlobalError()
        console.log(`[ Mobi Integration ] - Card Conditions are not available!
        CardId: ${propsMobiCard?.card_id}
        CardType: ${propsMobiCard?.card_type}
        ExternalId: ${externalId}
        Token: ${token}
        `)

        return ""
      }
    }

    const withAnonymousID = getUrlWithAnonymousId({
      choosenCard,
      refusedSHSuggestion,
      propsMobiCard,
      id,
      externalId,
      token,
    })

    const isItauPF =
      !!choosenCard?.choosen_card && choosenCard?.choosen_card === "itau-pf"
    const finalUrl =
      cameFromSortingHat && !isItauPF
        ? getSortingHatRefusedSuggestionUrl(withAnonymousID)
        : withAnonymousID

    track("redirectRequested", {
      redirectSlug: content.product_slug,
      originSlug: content.product_slug,
      redirectUrl: finalUrl,
      fireboltId: fireboltId,
    })

    await track("proposalStarted", {
      initialSlug: content.product_slug,
      finalSlug: content.product_slug,
      category: content.product_category,
      redirectUrl: finalUrl,
      fireboltId: fireboltId || id,
      rdg: getRDG(formEndPayload.capturedData.cpf || ""),
    }).catch(console.error)

    if (window.parent.location.href !== window.location.href) {
      window.top.location.href = finalUrl
    } else {
      window.location.href = finalUrl
    }
  }

  function getSortingHatRefusedSuggestionUrl(url) {
    const sortingHatParams = getSortingHatUrlParams({
      fireboltId,
      anonymousId,
      suggestionUrl: url,
    })
    const hasSortingHatParams = sortingHatParams !== ""
    const urlWithNewParams = `${url}${sortingHatParams}`

    return hasSortingHatParams ? urlWithNewParams : url
  }

  function getUrlByTree(linksTree, application) {
    const linkTreeFindedIQ = linksTree?.find(
      (linkTree) =>
        linkTree?.application === "iq-links" ||
        linkTree?.application === "cp-unlogged-links"
    )
    const linkTreeFindedCP = linksTree?.find(
      (linkTree) => linkTree?.application === "cp-unlogged-links"
    )
    const links = application === "cp" ? linkTreeFindedCP : linkTreeFindedIQ
    return links || {}
  }

  function handleSortingHatRedirect({
    suggestionCard,
    payload,
    mobiPayload,
    application,
  }) {
    const { slug: suggestionSlug } = suggestionCard
    const suggestionUrl = suggestionCard?.links
      ? getUrlByTree(suggestionCard?.links, application)?.link_pre_approved?.url
      : suggestionCard?.redirect_url

    const url = fillParams(suggestionUrl)

    const sortingHatParams = getSortingHatUrlParams({
      fireboltId,
      anonymousId,
      suggestionUrl,
    })

    let redirectUrl = new URL(`${url}${sortingHatParams}`)

    const isRedirectToIQ = redirectUrl.hostname === "www.iq.com.br"
    const isFireboltForm = redirectUrl.pathname.includes("/produto")
    const { externalId, token } = mobiPayload

    if (externalId && token && suggestionCard?.integrations?.length > 0) {
      const { integrations } = suggestionCard
      const randomHash =
        suggestionSlug === "click-platinum"
          ? "MhXBMIZMAg"
          : getRandomHashMobi(dataHashsMobi)
      redirectUrl = new URL(
        formatMobiUrl({
          anonymousId,
          fireboltId,
          cardId: integrations[0]?.card_id,
          cardSlug: payload.choosen_card,
          cardType: integrations[0]?.card_type,
          externalId,
          token,
          randomHash,
          isSortingHat: true,
        })
      )
    }

    if (payload.choosen_card === "neon") {
      const redirectUrlFirebolt = redirectListFirebolt?.find(
        (item) => item.param === "sh"
      )
      const redirectUrlNeon = redirectUrlFirebolt?.redirect ?? redirectUrl.href
      return redirectUrlNeon
    }

    if (!isRedirectToIQ || !isFireboltForm) {
      if (suggestionSlug?.includes("account-itau-pf")) {
        const itauPfUrl = replaceItauPfUrlParams({
          url: url,
          fireboltId,
        })
        return itauPfUrl
      }

      return redirectUrl.href
    }

    const autofillURL = formatAutofillUrl({ url: redirectUrl, payload })
    return autofillURL
  }

  function fillParams(url) {
    const startSourceUrl = getCookie("start_source_url")
    const siteId = getSiteIdParam({marketplace: 'cp', startSourceUrl, datasource: dimensions})
    const deliveryId = getDeliveryIdParam({startSourceUrl})

    const urlWithParams =
      url.replace('(firebolt_id)', fireboltId)
      .replace('(af_siteid)', siteId)
      .replace('(delivery_id)', deliveryId)

    return urlWithParams
  }

  return {
    getUrlWithAnonymousId,
    makeHandleRedirect,
    handleSortingHatRedirect,
  }
}

export default useRedirects
